import React, { Component } from "react";
import Slider from "react-slick";
import eyup from '../../assets/img/eyup-otman.png';

import './slick.min.css';
import './banner.css';


class Banner extends Component {


    render() {
    	const settings = {
	      dots: false,
	      arrows:false,
	      fade: true,
	      infinite: true,
	      speed: 500,
	      slidesToShow: 1,
	      slidesToScroll: 1,
	      //autoplay:true,
	    };
        return (
            <section id="banner" className="w100 left">
		        <Slider className="w100 left" {...settings}>
		          <div className="item pos-r bn on bsn window-h100 col-xs-ha col-xs-pb35">
		          	<div className="w100 h100 center-h">
		          		<div className="center-content w100 left t-center">
			            	<div className="container-fluid">
			            		<div className="row">
									<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-xs-pt45 responsive-item">
				            			<img className="center-w w60" alt="Eyüp OTMAN" src={eyup}></img>
				            		</div>
				            		<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 responsive-item responsive-text center-h">
				            			<div className="center-content w75 left white t-left col-sm-fn col-sm-table col-sm-ma col-sm-mb0 col-sm-t-center col-sm-w100">
				            				<h3 className="dinpro-bold fs30 col-sm-fs24 col-xs-fs20">Tüm Müzik Marketlerde !</h3>
				            				<h2 className="dinpro-bold fs40 colored2 col-sm-fs34 col-xs-fs30">İstiklal ve İstikbal Seslenişi</h2>
				            				<article className="w100 left dinpro-light op6 fs22 lh32 col-sm-fs18 col-xs-fs16 col-xs-lh22">Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
				            				</article>
				            				<div className="row">
				            					<ul className="w100 left mb0 mt45 buttoncontent col-xs-mt25">
					            					<li className="w50 left pl15 pr15">
					            						<a href="#rated" className="w100 left colored2-bg b-ra30 p15 white dinpro-bold t-center fs18 lh32 colored-hover2 soft col-xs-fs16 col-xs-pl5 col-xs-pr5 col-xs-pt5 col-xs-pb5" style={{ boxShadow:'0px 11px 60px 12px rgba(4, 4, 4, 0.39)' }}>
					            							<i className="icon icon-headhones2 pr5"></i>
					            							<span>Hemen</span> Dinle
					            						</a>
					            					</li>
					            					<li className="w50 left pl15 pr15">
					            						<a href="#0" className="w100 left colored2-bg b-ra30 p15 white dinpro-bold t-center fs18 lh32 colored-hover2 soft col-xs-fs16 col-xs-pl5 col-xs-pr5 col-xs-pt5 col-xs-pb5" style={{ boxShadow:'0px 11px 60px 12px rgba(4, 4, 4, 0.39)' }}>
					            							<i className="icon icon-music4 pr5"></i>
					            							Satın Al
					            						</a>
					            					</li>
					            				</ul>
				            				</div>
				            				
				            			</div>
				            		</div>
			            		</div>
			            		
			            	</div>
			            </div>
		          	</div>		            
		          </div>
		        </Slider>
		      </section>
        );
    }
}

export default Banner;
