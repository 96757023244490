import React, { Component } from 'react';
import GalleryContainer from '../gallery/GalleryContainer.js';
import ReactTooltip from 'react-tooltip';
import './contact.css';

class Contact extends Component {

    render() {
        return (
            <section id="contact" className="w100 left">
            	<div className="container-fluid pt25 pb25">
            		<div className="center-w w90 pt30 pb30 pl15 pr15">
            			<div className="row">
            				<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 p15">
            					<div className="w30 col-xs-w100 col-sm-w50 left pr15">
            						<div className="title w100 left t-left dinpro-bold colored2 fs18 flex">
		            					{/* Brand */}
					                    <div className="brandcontent left t-center">
					                        <a href="#banner" id="brand" className="b-circle left colored2-bg bb2 bl2 br2 bt2 bc1 center-h" style={{ height:'45px', width:'45px', }}>   
					                            {/*<img className="w100 ha b-circle" src={logo}></img>*/}          
					                            <div className="center-w white dinpro-medium fs14 lh14 op9">EO</div>
					                        </a>
					                    </div>
					                    {/* Brand End */}
					                    <a href="#banner" className="left pl15 center-h fs22 colored2">Eyüp OTMAN</a>
		            				</div>
		            				<article className="w100 left mt25 trajan-bold t-left white fs17 lh30 op8">
		            					<i>"Gece kalkıp gözyaşımı akıtsam, Secde edip şeytanımla takışsam, O secdede Rabbim İle buluşsam, Ben affına muhtacım Allah’ım."
		            					<div className="clear mt15 mb15"></div>
		            					Seherlerin sesini duyan gelsin bu meydâne, Seherlerde secdesini kılan gelsin bu neydâne.
		            					<div className="clear mt15 mb15"></div>
		            					<span className="left mt15 colored2">E.OTMAN</span></i>
		            				</article>
            					</div>
            					<div className="w70 col-xs-w100 col-sm-w50 left pl30 col-xs-pl0">
            						{/* Min Gallery */}
		            				<GalleryContainer/>
		            				{/* Min Gallery End */}
            					</div>            				
	            			</div>
	            			<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 p15 mt15 pt15 bt1 bc3 pl0 pr0">
	            				<div className="row">
	            					<div className="col-xl-6 col-lg-6 col-md-12 col-xs-12">
	            						<ul className="w100 left mb0 dinpro-regular fs14 col-sm-wi col-sm-table col-sm-ma col-sm-fn">
	            							<li className="left wi pr15">
	            								<a href="#banner" className="white left op7 colored-hover-text2 soft">Anasayfa</a>
	            							</li>
	            							<li className="left wi pr15">
	            								<a href="#albums" className="white left op7 colored-hover-text2 soft">Albümler</a>
	            							</li>
	            							<li className="left wi pr15">
	            								<a href="#artist" className="white left op7 colored-hover-text2 soft">Eyüp OTMAN Kimdir?</a>
	            							</li>
	            							<li className="left wi pr15">
	            								<a href="#rated" className="white left op7 colored-hover-text2 soft">Ücretsiz Dinle</a>
	            							</li>
	            							<li className="left wi pr15">
	            								<a href="#contact" className="white left op7 colored-hover-text2 soft">İletişime Geç</a>
	            							</li>
	            						</ul>
	            					</div>
	            					<div className="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-mt25 col-sm-mb15">
			            				{/*  */}
					                    <ul className="right wi col-sm-fn col-sm-table col-sm-ma">
					                    	<li className="left wi soft ml15 col-sm-w100" data-tip="Haritayı Görüntülemek İçin Tıklayın">
					                            <a href="https://goo.gl/maps/sNHFeL7527jDCWMU9" target="_blank" rel="noopener noreferrer" className="w100 left p15 pt7 pb7 soft white col-sm-fn col-sm-table col-sm-wi col-sm-ma">
					                                <i className="icon icon-marker fs16 lh16 left col-sm-fn col-sm-table col-sm-ma"><span className="dinpro-regular fs14 lh16 t-left owh soft right pl10">Vişne Cad. YILDIRIM / BURSA - TÜRKİYE</span></i>
					                            </a>
					                        </li>	
					                        <li className="left wi soft ml15 col-sm-w100" data-tip="Bir Mail Gönderin">
					                            <a href="mailto:example@domain.com" target="_blank" rel="noopener noreferrer" className="w100 left p15 pt7 pb7 soft white col-sm-fn col-sm-table col-sm-wi col-sm-ma">
					                                <i className="icon icon-mail fs16 lh16 left col-sm-fn col-sm-table col-sm-ma"><span className="dinpro-regular fs14 lh16 t-left owh soft right pl10">example@domain.com</span></i>
					                            </a>
					                        </li>
					                        <li className="left wi soft ml15 col-sm-w100 col-sm-mt15 col-sm-mb15" data-tip="Bu Proje Bir SERENAY Yapımıdır.">
					                            <a href="https://www.serenay.net.tr/" target="_blank" rel="noopener noreferrer" className="w100 left p15 pt7 pb7 soft colored2 col-sm-fn col-sm-table col-sm-wi col-sm-ma">
					                                <i className="icon icon-serenay fs16 lh16 left spin col-sm-fn col-sm-table col-sm-ma col-sm-fs30 col-sm-lh30"></i>
					                            </a>
					                        </li>					                        				                                           
					                    </ul>
					                    {/*  End */}
	            					</div>
	            				</div>
	            			</div>
            			</div>            			
            		</div>
            	</div>
            </section>
        );
    }
}

export default Contact;
