import React, { Component } from 'react';
import AudioCard from 'audiocard';
import Tilt from 'react-tilt';
import ReactTooltip from 'react-tooltip';
import './rated.css';

import plak from '../../assets/img/rated/plak.png';
import key from '../../assets/img/rated/player-key.png';

import album1 from '../../assets/img/albums/album1.jpg';
import album2 from '../../assets/img/albums/album2.jpg';
import album3 from '../../assets/img/albums/album3.jpg';
import album4 from '../../assets/img/albums/album4.jpg';
import album5 from '../../assets/img/albums/album5.jpg';


class Rated extends Component {

    render() {
        return (
            <section id="rated" className="w100 left pt5 pb15 pos-r owh">
            	<div className="plak w100 left">
            		<div className="center-w pos-r">
            			<div className="w100 left turnplak spin">
            				<img className="w100 left b-black b-circle" alt="Plak" src={plak}></img>
            				<div className="pos-a ll0 lt0 w100 h100 center-h">
            					<div className="center-w">
            						<i className="icon icon-headhones2 colored2 op2 b-circle p35 bb1 bl1 br1 bt1" style={{ fontSize:'6vw', lineHeight:'6vw' }}></i>
            					</div>
            				</div>            				
            			</div>            			
            			<img className="soft pos-a table wi mb0 key" alt="Key" src={key}></img>
            			{/* Title */}
            			<div className="pos-a w100 ll0 colored2 dinpro-bold fs26 lh36" style={{ bottom:'23%' }}><span className="white fs36">En Çok</span><br/>Beğenilen Eserler</div>
            			{/* Title End */}
            		</div>
            	</div>
            	<div className="w100 left mt45">
            		<div className="container-fluid">
            			<div className="center-w w90 pt30 pb30">
            				<ul className="w100 left mb0">
            					<li className="w20 col-sm-w33 col-xs-w100 left mb30 pl15 pr15">
                                    <div className="w100 left pos-r">
                                        <Tilt className="Tilt w100 left b-ra15 owh" options={{ max : 25 }} >
                                            <div className="Tilt-inner">
                                                    <img className="w100 left b-ra15" alt="İstiklal ve İstikbal Seslenişi" src={album1}></img>
                                                </div>
                                                <div className="w100 h100 left pos-a ll0 lb0 b-ra15 owh">
                                                <AudioCard
                                                  source="http://cetinleriplik.com/sermobilepro/static/test/music.mp3"
                                                  skipBackSeconds={10}
                                                  skipForwardSeconds={30}
                                                  color="#fff"
                                                  background="transparent"
                                                  progressBarBackground="rgba(255,255,255,.3)"
                                                  progressBarCompleteBackground="#fff"
                                                  autoPlay={false}
                                                  preload="auto"
                                                  className="playercard left w100"
                                                  link="https://soundcloud.com/brahim-at/tracks"
                                                  linkText={<i className='icon icon-music3 fs32' data-tip='Tracklist'></i>}
                                                />
                                            </div>
                                        </Tilt>                                         
                                    </div>                                                						
            					</li>
            					<li className="w20 col-sm-w33 col-xs-w100 left mb30 pl15 pr15">
                                    <div className="w100 left pos-r">
                                        <Tilt className="Tilt w100 left b-ra15 owh" options={{ max : 25 }} >
                                            <div className="Tilt-inner">
                                                    <img className="w100 left b-ra15" alt="İstiklal ve İstikbal Seslenişi" src={album2}></img>
                                                </div>
                                                <div className="w100 h100 left pos-a ll0 lb0 b-ra15 owh">
                                                <AudioCard
                                                  source="http://cetinleriplik.com/sermobilepro/static/test/music.mp3"
                                                  skipBackSeconds={10}
                                                  skipForwardSeconds={30}
                                                  color="#fff"
                                                  background="transparent"
                                                  progressBarBackground="rgba(255,255,255,.3)"
                                                  progressBarCompleteBackground="#fff"
                                                  autoPlay={false}
                                                  preload="auto"
                                                  className="playercard left w100"
                                                  link="https://soundcloud.com/brahim-at/tracks"
                                                  linkText={<i className='icon icon-music3 fs32' data-tip='Tracklist'></i>}
                                                />
                                            </div>
                                        </Tilt>                                         
                                    </div>                                                                      
                                </li>
            					<li className="w20 col-sm-w33 col-xs-w100 left mb30 pl15 pr15">
                                    <div className="w100 left pos-r">
                                        <Tilt className="Tilt w100 left b-ra15 owh" options={{ max : 25 }} >
                                            <div className="Tilt-inner">
                                                    <img className="w100 left b-ra15" alt="İstiklal ve İstikbal Seslenişi" src={album3}></img>
                                                </div>
                                                <div className="w100 h100 left pos-a ll0 lb0 b-ra15 owh">
                                                <AudioCard
                                                  source="http://cetinleriplik.com/sermobilepro/static/test/music.mp3"
                                                  skipBackSeconds={10}
                                                  skipForwardSeconds={30}
                                                  color="#fff"
                                                  background="transparent"
                                                  progressBarBackground="rgba(255,255,255,.3)"
                                                  progressBarCompleteBackground="#fff"
                                                  autoPlay={false}
                                                  preload="auto"
                                                  className="playercard left w100"
                                                  link="https://soundcloud.com/brahim-at/tracks"
                                                  linkText={<i className='icon icon-music3 fs32' data-tip='Tracklist'></i>}
                                                />
                                            </div>
                                        </Tilt>                                         
                                    </div>                                                                      
                                </li>
                                <li className="w20 col-sm-w33 col-xs-w100 left mb30 pl15 pr15">
                                    <div className="w100 left pos-r">
                                        <Tilt className="Tilt w100 left b-ra15 owh" options={{ max : 25 }} >
                                            <div className="Tilt-inner">
                                                    <img className="w100 left b-ra15" alt="İstiklal ve İstikbal Seslenişi" src={album4}></img>
                                                </div>
                                                <div className="w100 h100 left pos-a ll0 lb0 b-ra15 owh">
                                                <AudioCard
                                                  source="http://cetinleriplik.com/sermobilepro/static/test/music.mp3"
                                                  skipBackSeconds={10}
                                                  skipForwardSeconds={30}
                                                  color="#fff"
                                                  background="transparent"
                                                  progressBarBackground="rgba(255,255,255,.3)"
                                                  progressBarCompleteBackground="#fff"
                                                  autoPlay={false}
                                                  preload="auto"
                                                  className="playercard left w100"
                                                  link="https://soundcloud.com/brahim-at/tracks"
                                                  linkText={<i className='icon icon-music3 fs32' data-tip='Tracklist'></i>}
                                                />
                                            </div>
                                        </Tilt>                                         
                                    </div>                                                                      
                                </li>
                                <li className="w20 col-sm-w33 col-xs-w100 left mb30 pl15 pr15">
                                    <div className="w100 left pos-r">
                                        <Tilt className="Tilt w100 left b-ra15 owh" options={{ max : 25 }} >
                                            <div className="Tilt-inner">
                                                    <img className="w100 left b-ra15" alt="İstiklal ve İstikbal Seslenişi" src={album5}></img>
                                                </div>
                                                <div className="w100 h100 left pos-a ll0 lb0 b-ra15 owh">
                                                <AudioCard
                                                  source="http://cetinleriplik.com/sermobilepro/static/test/music.mp3"
                                                  skipBackSeconds={10}
                                                  skipForwardSeconds={30}
                                                  color="#fff"
                                                  background="transparent"
                                                  progressBarBackground="rgba(255,255,255,.3)"
                                                  progressBarCompleteBackground="#fff"
                                                  autoPlay={false}
                                                  preload="auto"
                                                  className="playercard left w100"
                                                  link="https://soundcloud.com/brahim-at/tracks"
                                                  linkText={<i className='icon icon-music3 fs32' data-tip='Tracklist'></i>}
                                                />
                                            </div>
                                        </Tilt>                                         
                                    </div>                                                                      
                                </li>
            				</ul>            				
            			</div>
            		</div>
            	</div>
            </section>
        );
    }
}

export default Rated;
