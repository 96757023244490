import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import logo from '../../assets/img/logo.jpg';
import './navbar.css';

class Navbar extends Component {

    render() {
        return (
            <nav className="pos-f h100 ll0 lt0 z150 colored-bg pos-r col-xs-pb30 soft-slow">
                <div className="scrollcontent w100 h100 left owy-a">
                    <ReactTooltip />
                     {/* Mobil Menü Toggle */}
                    <div className="navtoggle pos-a lt15 lr0 colored-bg p15 bbr-ra25 btr-ra25 cur-p" style={{ marginRight:'-46px' }}>
                        <i className="icon icon-menu3 white"></i>
                    </div>
                    {/* Mobil Menü Toggle End */}
                    {/* Brand */}
                    <div className="brandcontent center-w t-center p15">
                        <a href="index.php" id="brand" className="b-circle left colored2-bg bb2 bl2 br2 bt2 bc1 mt25 center-h" style={{ height:'60px', width:'60px', }}>   
                            {/*<img className="w100 ha b-circle" src={logo}></img>*/}          
                            <div className="center-w white dinpro-bold fs18 lh18 op9">EO</div>
                        </a>
                    </div>
                    {/* Brand End */}
                    {/* Menü */}
                    <ul id="mainul" className="w100 left mb0 t-center soft" style={{ marginTop:'5vh' }}>
                        <li className="w100 left soft" data-tip="Anasayfa">
                            <a href="#banner" className="w100 left bb1 bc2 p15 soft">
                                <div className="center-w p0">
                                    <i className="icon icon-home fs26 lh26 soft"><span className="dinpro-regular fs14 lh26 t-left owh soft">Anasayfa</span></i>
                                </div>                                
                            </a>
                        </li>
                        <li className="w100 left soft" data-tip="Albümler">
                            <a href="#albums" className="w100 left bb1 bc2 p15 soft">
                                <div className="center-w p0">
                                    <i className="icon icon-music1 fs26 lh26 soft"><span className="dinpro-regular fs14 lh26 t-left owh soft">Albümler</span></i>
                                </div>                                
                            </a>
                        </li>
                        <li className="w100 left soft" data-tip="Eyüp OTMAN Kimdir?">
                            <a href="#artist" className="w100 left bb1 bc2 p15 soft">
                                <div className="center-w p0">
                                    <i className="icon icon-mic2 fs26 lh26 soft"><span className="dinpro-regular fs14 lh26 t-left owh soft">Eyüp OTMAN Kimdir?</span></i>
                                </div>                                
                            </a>
                        </li>                        
                        <li className="w100 left soft" data-tip="Ücretsiz Dinle">
                            <a href="#rated" className="w100 left bb1 bc2 p15 soft">
                                <div className="center-w p0">
                                    <i className="icon icon-headhones2 fs26 lh26 soft"><span className="dinpro-regular fs14 lh26 t-left owh soft">Ücetsiz Dİnle</span></i>
                                </div>                                
                            </a>
                        </li>
                        <li className="w100 left soft" data-tip="İletişime Geç">
                            <a href="#contact" className="w100 left bb1 bc2 p15 soft">
                                <div className="center-w p0">
                                    <i className="icon icon-comment2 fs26 lh26 soft"><span className="dinpro-regular fs14 lh26 t-left owh soft">İletişime Geç</span></i>
                                </div>                                
                            </a>
                        </li>
                    </ul>
                    {/* Menü End */}
                    <div className="clear"></div>
                    {/* Sosyal Medya */}
                    <ul id="social" className="w100 left mb0 t-center soft pos-a lb25 col-xs-pos-r col-xs-mt30 col-xs-lt30">
                        <li className="w100 col-xs-w25 left soft" data-tip="Deezer">
                            <a href="https://www.deezer.com/tr/artist/69953402/radio?autoplay=true" target="_blank" rel="noopener noreferrer" className="w100 left p15 pt7 pb7 soft">
                                <i className="icon icon-deezer fs16 lh16"></i>
                            </a>
                        </li>
                        <li className="w100 col-xs-w25 left soft" data-tip="Facebook">
                            <a href="https://www.facebook.com/Cntmuzik/posts/410839749713536?__xts__[0]=68.ARBCV8jRw9W8Qr3vNZdcgSQeqbilE9MGMwrFMbWYn_qCiU2aupV2ZYVoQ_JsxrXorQCbaOYO5EJPmKX8MhIBTci0AKhgT5MnkKKMj15puuqFiRvDESXaB055PJYyOxEoKYfI7M61dUJvVT1ScGN9UElA9TALMvS8hG8Rh65QEmCyGbRwsTvOI5oHXTSVW4H6kBDWzCW7RV13LLQGWcM-xfHrRInkeERs65MtZNx-i0jFVta-P5z0AEb7mIDes6Jgp1rfyVU2KEF6MaCNdLMiXRFOfOfcizpsCRwvVO9mHm1Cq4unS2nNL06O7xnuugbjk5fnbW2pa0M0isXRobgPHJs&__xts__[1]=68.ARAFxHwsXgxzsHiwJXzMnAKzEyJufPQ8q4drUEw7XTPT8Hho-xg6_-MCa0jt57RYcIkuiTSVWg1gq8GoUjRHUd_Z7rBdq--NDI04aAdJzx9SAa76J3a_2BaAs2znQ2LTsmjIePFxER6SZBemLrvoAmBaX0MW-DCHu8Sf4h04Khke5-Le6zwVuGrzCxAmieVQpMvNq_fVLpBxSTT9onw02tqRpWcFSYtSGXJ8nspGr9tYZXMgtSCDtnp0iNp3UY6hGnGnysAfUwsuKtb6Y4Len1hwGzJ9kIwS3IDHZ5d1kefpNw_YoQlK9jfb7lkrCjQwo5JfEUgY07d6VNgBSdLxFVI&__tn__=-R" target="_blank" rel="noopener noreferrer" className="w100 left p15 pt7 pb7 soft">
                                <i className="icon icon-facebook fs16 lh16"></i>
                            </a>
                        </li>
                        <li className="w100 col-xs-w25 left soft" data-tip="Twitter">
                            <a href="https://twitter.com/eyup_otman" target="_blank" rel="noopener noreferrer" className="w100 left p15 pt7 pb7 soft">
                                <i className="icon icon-twitter fs16 lh16"></i>
                            </a>
                        </li>
                        <li className="w100 col-xs-w25 left soft" data-tip="Youtube">
                            <a href="https://www.youtube.com/watch?v=4aGkeF5GhGs&list=PLGsdck1G6fCcizP-Ruvv8iG8TZSLR9Vgf&index=1" target="_blank" rel="noopener noreferrer"  className="w100 left p15 pt7 pb7 soft">
                                <i className="icon icon-youtube fs16 lh16"></i>
                            </a>
                        </li>                    
                    </ul>
                    {/* Sosyal Medya End */}
                </div>          
            </nav>
        );
    }
}

export default Navbar;
