import React, { Component } from 'react';
import Tilt from 'react-tilt'
import './albums.css';

import album1 from '../../assets/img/albums/album1.jpg';
import album2 from '../../assets/img/albums/album2.jpg';
import album3 from '../../assets/img/albums/album3.jpg';
import album4 from '../../assets/img/albums/album4.jpg';
import album5 from '../../assets/img/albums/album5.jpg';


class Albums extends Component {

    render() {
        return (
            <section id="albums" className="w100 left pt45 pb45 pos-r pos-r z10">
            	<div className="container-fluid">
            		<div className="center-w w90">
            			{/* Head */}
	            		<div className="head w100 left t-left pl15 pr15 colored2 pt25 pb25 bb1 bc-colored">	            			
	            			<i className="icon icon-music1 fs26 lh26 soft left"></i><span className="dinpro-bold fs16 lh26 left pl10">Albümler</span>	            		
	            		</div>
	            		{/* Head End */}
	            		{/* Album List */}
	            		<ul className="w100 left mb0 mt25 albumlist">
	            			<li className="w20 col-sm-w33 col-xs-w50 left p15 col-xs-pl5 col-xs-pr5 col-xs-pt5 col-xs-pb5">
	            				<a className="w100 left" href="#rated">
		            				<Tilt className="Tilt w100 left" options={{ max : 25 }} >
									 	<div className="Tilt-inner">
									 		<img className="w100 left" alt="İstiklal ve İstikbal Seslenişi" src={album1}></img>
									 	</div>
									</Tilt>        
								</a>    				
	            			</li>
	            			<li className="w20 col-sm-w33 col-xs-w50 left p15 col-xs-pl5 col-xs-pr5 col-xs-pt5 col-xs-pb5">
	            				<a className="w100 left" href="#rated">
		            				<Tilt className="Tilt w100 left" options={{ max : 25 }} >
									 	<div className="Tilt-inner">
									 		<img className="w100 left" alt="Toprak Kokusu" src={album2}></img>
									 	</div>
									</Tilt> 
								</a>           				
	            			</li>
	            			<li className="w20 col-sm-w33 col-xs-w50 left p15 col-xs-pl5 col-xs-pr5 col-xs-pt5 col-xs-pb5">
	            				<a className="w100 left" href="#rated">
		            				<Tilt className="Tilt w100 left" options={{ max : 25 }} >
									 	<div className="Tilt-inner">
									 		<img className="w100 left" alt="Aziz Üstadım Benim" src={album3}></img>
									 	</div>
									</Tilt>   
								</a>         				
	            			</li>
	            			<li className="w20 col-sm-w33 col-xs-w50 left p15 col-xs-pl5 col-xs-pr5 col-xs-pt5 col-xs-pb5">
	            				<a className="w100 left" href="#rated">
		            				<Tilt className="Tilt w100 left" options={{ max : 25 }} >
									 	<div className="Tilt-inner">
									 		<img className="w100 left" alt="Selamı Yayalım" src={album4}></img>
									 	</div>
									</Tilt>        
								</a>    				
	            			</li>
	            			<li className="w20 col-sm-w33 col-xs-w50 left p15 col-xs-pl5 col-xs-pr5 col-xs-pt5 col-xs-pb5">
	            				<a className="w100 left" href="#rated">
		            				<Tilt className="Tilt w100 left" options={{ max : 25 }} >
									 	<div className="Tilt-inner">
									 		<img className="w100 left" alt="Hüzün Yağmurları" src={album5}></img>
									 	</div>
									</Tilt>  
								</a>          				
	            			</li>
	            		</ul>
	            		{/* Album List End */}
            		</div>
            		
            	</div>
            </section>
        );
    }
}

export default Albums;
