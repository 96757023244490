import React, { Component } from 'react';
import Gallery from 'lightbox-alex-react';
import './gallery.css';




const images = [
		'https://picsum.photos/700/700?random=1','https://picsum.photos/700/700?random=2','https://picsum.photos/700/700?random=3','https://picsum.photos/700/700?random=4','https://picsum.photos/700/700?random=5','https://picsum.photos/700/700?random=6','https://picsum.photos/700/700?random=7','https://picsum.photos/700/700?random=8','https://picsum.photos/700/700?random=9','https://picsum.photos/700/700?random=10']
const thumbnails = [
		'https://picsum.photos/700/700?random=1','https://picsum.photos/700/700?random=2','https://picsum.photos/700/700?random=3','https://picsum.photos/700/700?random=4','https://picsum.photos/700/700?random=5','https://picsum.photos/700/700?random=6','https://picsum.photos/700/700?random=7','https://picsum.photos/700/700?random=8','https://picsum.photos/700/700?random=9','https://picsum.photos/700/700?random=10']

class GalleryContainer extends Component {
   
    render() {
        return (
      	  <Gallery files={images} thumbnails={thumbnails}/>
        );
    }
}

export default GalleryContainer;
