import React, { Component } from 'react';
import './artist.css';

import artist1 from '../../assets/img/artist1.jpg';

class Artist extends Component {

    render() {
        return (
            <section id="artist" className="w100 left bs1 pt15 pb35">
            	<div className="container-fluid">
            		<div className="center-w w90 pt30 pb30">

            			<div className="artistCard p15">
            				<div className="w35 col-sm-w100 col-xs-w100 left visual">
            					<img className="w100 left bs3 b-ra10" alt="Eyüp OTMAN" src={artist1}></img>
            				</div>
            				<div className="w65 left col-sm-w100 col-xs-w100 white t-left details pt25 pl30 col-sm-pl0">
            					<div className="date arial-regular fs16 lh16 op5 w100 left col-xs-pl0 col-xs-pr0">BURSA - 1953</div>
            					<div className="w100 left col-xs-pl0 col-xs-pr0 dinpro-medium fs26">Eyüp OTMAN</div>            					
            					<article className="w100 left col-xs-pl0 col-xs-pr0 trajan-bold mt15"><i>"Sadakte ve bilhakkı natakte, ömürler geçiyor yatakte, biz yatarken yatakte; cümle âlem atakte..."</i></article>
            					<article className="w100 left mt25 col-xs-pl0 col-xs-pr0 dinpro-light fs20 lh30 op6">
            						<p>
            							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            						</p>
            						<p className="fs16 op6">
            							Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book. It usually begins with
            						</p>
            					</article>
            					<div className="w100 left col-xs-pl0 col-xs-pr0 mt25">
            						<ul className="colored2-bg wi left p10 pl20 pr25 b-ra30 bs1 mr25 soft colored-hover2 col-xs-w100 col-xs-flex col-xs-mb15">
            							<li className="left mr15 col-xs-w100 col-xs-t-center col-xs-mr0">
            								<a className="w100 left white" href="#0"><i className="icon icon-deezer"></i></a>
            							</li>
            							<li className="left mr15 col-xs-w100 col-xs-t-center col-xs-mr0">
            								<a className="w100 left white" href="#0"><i className="icon icon-facebook"></i></a>
            							</li>
            							<li className="left mr15 col-xs-w100 col-xs-t-center col-xs-mr0">
            								<a className="w100 left white" href="#0"><i className="icon icon-twitter"></i></a>
            							</li>
            							<li className="left col-xs-w100 col-xs-t-center col-xs-mr0">
            								<a className="w100 left white" href="#0"><i className="icon icon-youtube"></i></a>
            							</li>
            						</ul>
            						<div className="colored2-bg wi left bs1 mr25 b-ra30 dinpro-medium soft colored-hover2 col-xs-w100 col-xs-mb15">
            							<a className="p10 pl20 pr25 left white col-xs-w100 t-center" href="#0"><i className="icon icon-comment2 mr5"></i>İletişime Geç</a>
            						</div>
            						<div className="colored2-bg wi left bs1 mr25 b-ra30 dinpro-medium soft colored-hover2 col-xs-w100 col-xs-mb15">
            							<a className="p10 pl20 pr25 left white col-xs-w100 t-center" href="#albums"><i className="icon icon-headhones2 mr5"></i>Albümleri İncele</a>
            						</div>
            					</div>
            				</div>
            			</div>
            		</div>
            	</div>
            </section>
        );
    }
}

export default Artist;
