import React from 'react';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/default-bootstrap.min.css';
import './assets/css/colored.css';
import './assets/css/font.css';
import './assets/css/custom-general.css';

import Navbar from './components/navbar/Navbar.js';
import Banner from './components/banner/Banner.js';
import Player from './components/player/Player.js';
import Albums from './components/albums/Albums.js';
import Artist from './components/artist/Artist.js';
import Rated from './components/rated/Rated.js';
import Contact from './components/contact/Contact.js';

function App() {
  return (
    <div className="App">

    <Navbar/>
    <main className="w100 left pl90 col-xs-pl0">
    	<Banner/>
    	<Player/>
    	<Albums/>
    	<Artist/>
    	<Rated/>
    	<Contact/>
    </main>
    

































    </div>
  );
}

export default App;
