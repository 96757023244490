import React, { Component } from 'react';
import AudioCard from 'audiocard';
import './player.css';

import album1 from '../../assets/img/albums/album1.jpg';


class Player extends Component {
  
    render() {
        return (
            <section id="player" className="w100 left pos-f ll0 lb0 z50 pl90 bs1 col-xs-pl0" style={{ backgroundColor:'rgba(0,0,0,.5)' }}>
					<AudioCard
					  art={album1}
					  source="http://cetinleriplik.com/sermobilepro/static/test/music.mp3"
					  skipBackSeconds={10}
					  skipForwardSeconds={30}
					  color="#fff"
					  background="transparent"
					  progressBarBackground="rgba(255,255,255,.3)"
					  progressBarCompleteBackground="#fff"
					  autoPlay={true}
					  preload="auto"
					  className="playercard"
					/>
            </section>
        );
    }
}

export default Player;
